import router from '@/router/index.js';
import { axiosCrf } from '@/services/config.js';

let configforXLS = {
  responseType: 'arraybuffer',

  contentType: 'application/force-download',

  'Content-Disposition': 'attachment',
};

axiosCrf.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    
    if (error.response.status === 401) {
      localStorage.removeItem('access_Token');
      localStorage.removeItem('refresh_Token');
      localStorage.setItem('Error', 'Token vencido, por favor fazer login novamente.');
      router.push('/');
    }
    return Promise.reject(error);
  },
);

export default {
  crf: {

    getCrfHistorico(idCrf) {
      return axiosCrf.get('' + idCrf + '/historicCertificates');
    },
    search(pagination, params) {
      let sort = '';
      if (pagination.sort) {
        sort = '&sort=' + pagination.sort;
      }
      return axiosCrf.post('/certificates/search?page=' + pagination.page + '&size=' + pagination.size + sort, params);
    },
    save(crf) {
      return axiosCrf.post('/certificates/manual', crf);
    },
    update(id, crf) {
      return axiosCrf.put('/certificates/update/' + id, crf);
    },
    exportar(params) {
      return axiosCrf.post('/certificates/export', params, configforXLS);
    },
    delete(id) {
      return axiosCrf.delete('/certificates/delete/' + id);
    }
  },
  historic: {
    save(historic) {
      return axiosCrf.post('/historicUpdates', historic);
    },
    search() {
      return axiosCrf.get('/historicUpdates');
    },
    downloadReport(params) {
      return axiosCrf.post('/historicUpdates/export', params, configforXLS);
    },
    getDataAtualizada() {
      return axiosCrf.get('/historicUpdates/dataAtualizada');
    }
  }
};
