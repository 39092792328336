<template>
  <v-main>
    <v-row>
      <v-col cols="6" style="margin-top: 32px"></v-col>
    </v-row>
    <v-row>
      <v-col cols="6" style="margin-top: 32px; padding-bottom: 32px">
        <router-link to="/home" style="text-decoration: none"
          ><v-icon class="icon-home" style="margin-right: 16px !important;">mdi-home-outline</v-icon></router-link
        >
        <v-icon small class="mr-2 text-link">mdi-chevron-right</v-icon>
        <b class="text-link" style="color: #8d8d99; font-size: 14px; font-weight: 400; letter-spacing: 0.01em; margin-right: 16px !important;" > Certidões</b>
        <v-icon small class="mr-2 text-link">mdi-chevron-right</v-icon>
        <strong class="text-link">Certidões de Regularidade do FGTS</strong>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <div style="width: 530px; height: 50px; display: inline-block">
          <h2 class="mt-2 mb-8 titleColor--text">Certidões de Regularidade do FGTS (CRF)</h2>
        </div>
      </v-col>
      <v-col cols="2">
        <div style="width: 530px; height: 50px; display: inline-block; padding-left: 495px">
          <v-tooltip bottom>
            <template v-if="isAdmin" v-slot:activator="{ on, attrs }">
              <v-btn color="success" v-bind="attrs" :disabled="certificates == []" @click="openModal()" v-on="on">
                <span style="font-size: 14px !important">+</span> Adicionar
              </v-btn>
            </template>
            <span>Adicionar uma nova certidão</span>
          </v-tooltip>
        </div>
      </v-col>
      <v-col cols="6" class="h6">
        <p style="color: #575767" class="subtitle">
          Gerenciamento de certidões, com inclusão manual e automática, visualização de histórico e download
        </p>
      </v-col>
      <v-col cols="6">
        <p class="subtitle-link">
          <v-btn class="pa-4" style="color: #2d5796; background-color: transparent" @click="historic()">
            <span class="material-icons-outlined" style="font-size: 12px !important">restart_alt</span>
            <span class="mx-1" style="color: #2d5796; font-size: 12px !important"> Atualização realizada </span>
            <span style="color: #52526b; font-size: 12px !important"> em {{ ultimaDataAtualizacao }} </span>
          </v-btn>
        </p>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title class="pl-0 pr-0">
        <TableSearch
          id="idSearchBar"
          v-model="filter.codigo"
          label="Pesquisar por código..."
          @input="onInputCrfTableSearch"
        ></TableSearch>
        <v-spacer></v-spacer>
        <v-row>
          <v-col cols="12" sm="12" class="text-right">
            <span
              id="attachId"
              style="
                max-width: 384px !important;
                min-width: 384px !important;
                max-height: 544px !important;
                min-height: 544px !important;
              "
            >
              <v-menu
                offset-y
                left
                :close-on-content-click="false"
                v-model="openMenu"
                :close-on-click="closeOnClick"
                attach="#attachId"
                style="
                  max-width: 384px !important;
                  min-width: 384px !important;
                  max-height: 544px !important;
                  min-height: 544px !important;
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :class="isActive ? 'secundary-default-active' : 'secundary-default'"
                    style="width: 103px"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="clickButtonFilter()"
                  >
                    <v-icon>mdi-filter-outline</v-icon> Filtro
                    {{ totalSelectedFilters > 0 && enableCounter && !openMenu ? `(${totalSelectedFilters})` : '' }}
                  </v-btn>
                </template>
                <v-card
                  style="
                    max-width: 384px !important;
                    min-width: 384px !important;
                    max-height: 544px !important;
                    min-height: 544px !important;
                  "
                  class="mb-2 pa-0"
                >
                  <v-card-title class="pa-4" style="max-height: 64px !important">
                    <span
                      class="text-h5"
                      :style="'color: #182026;font-weight:700;line-height:30px!important;font-size:20px!important;height:30px!important'"
                      >Filtro</span
                    >
                    <v-spacer></v-spacer>
                    <v-btn icon dark class="pa-0 ma-0" @click="closeFromX">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>

                  <v-list
                    width="384"
                    style="
                      max-height: 480px !important;
                      min-height: 480px !important;
                      border-top: 1px solid var(--v-secondary-lighten5) !important;
                    "
                    class="ma-0 pa-0"
                  >
                    <v-list-item class="py-4">
                      <v-autocomplete
                        v-model="filter.cnpjs"
                        :items="companies"
                        item-text="details.socialName"
                        :item-value="(item) => item.details"
                        return-object
                        multiple
                        append-icon="mdi-chevron-down"
                        dense
                        outlined
                        label="Empresas"
                        clearable
                        hide-details
                        @click="habilitarChip"
                        @change="selectAllCompanies"
                      >
                        <template v-slot:selection="{ index }">
                          <span v-if="index === 0" style="max-height: 48px" class="primary--text">
                            {{
                              `${
                                filter.cnpjs.length - 1 == companies.length - 1
                                  ? companies.length - 2
                                  : filter.cnpjs.length - 1
                              } selecionado${filter.cnpjs.length > 1 ? 's' : ''}`
                            }}
                          </span>
                        </template>

                        <template v-slot:item="{ item, attrs, on }">
                          <v-list-item #default="{ active }" v-bind="attrs" :class="{ 'pl-10': item.group }" v-on="on">
                            <v-list-item-action>
                              <v-checkbox :input-value="active"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title v-if="item.socialName" v-html="item.socialName"></v-list-item-title>
                              <v-list-item-title v-else
                                >Todas as empresas ({{
                                  filter.cnpjs.length == 0
                                    ? filter.cnpjs.length
                                    : filter.cnpjs.length - 1 == companies.length - 1
                                    ? companies.length - 2
                                    : filter.cnpjs.length - 1
                                }})</v-list-item-title
                              >
                              <v-list-item-subtitle v-if="item.details && item.details.cnpj">
                                {{ item.details.cnpj | cnpj }}</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-list-item>

                    <v-list-item class="pb-4">
                      <v-dialog model="dialog" width="369px">
                        <v-date-picker
                          v-model="filter.emissao"
                          no-title
                          range
                          @change="checkSelectedFilters"
                        ></v-date-picker>
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            id="textPicker"
                            v-model="dateEmissaoFormated"
                            style="max-height: 48px !important; min-height: 48px !important"
                            outlined
                            label="Período de emissão"
                            readonly
                            dense
                            :error-messages="dateMessageError"
                            color="primary"
                            v-on="on"
                          >
                            <template slot="append">
                              <span class="material-icons-outlined mr-1">event</span>
                            </template>
                            </v-text-field>
                        </template>
                      </v-dialog>
                    </v-list-item>

                    <v-list-item class="pb-4" style="color: var(--v-primary-base)">
                      <v-dialog model="dialog" width="369px">
                        <v-date-picker
                          v-model="filter.validade"
                          no-title
                          range
                          @change="checkSelectedFilters"
                        ></v-date-picker>
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            id="textPicker"
                            v-model="dateValidadeFormated"
                            style="max-height: 48px !important; min-height: 48px !important"
                            color="primary"
                            outlined
                            label="Período de validade"
                            readonly
                            dense
                            :error-messages="dateMessageValidadeError"
                            v-on="on"
                          >
                            <template slot="append">
                              <span class="material-icons-outlined mr-1">event</span>
                            </template>
                          </v-text-field>
                        </template>
                      </v-dialog>
                    </v-list-item>

                    <v-list-item class="pb-4">
                      <v-combobox
                        v-model="filter.situacao"
                        style="max-height: 48px !important; min-height: 48px !important"
                        :items="situacoes"
                        label="Situação"
                        append-icon="mdi-chevron-down"
                        outlined
                        dense
                        @change="checkSelectedFilters"
                      >
                        <template slot="item" slot-scope="data">
                          {{ data.item | pendency }}
                        </template>
                        <template slot="selection" slot-scope="item">
                          <span class="primary--text"> {{ item.item | pendency }} </span>
                        </template>
                      </v-combobox>
                    </v-list-item>

                    <v-list-item class="pb-4">
                      <v-combobox
                        v-model="filter.inclusao"
                        v-bind:class="activeClass"
                        style="max-height: 48px !important; min-height: 48px !important"
                        append-icon="mdi-chevron-down"
                        :items="inclusoes"
                        label="Inclusão"
                        outlined
                        dense
                        @change="checkSelectedFilters"
                      >
                        <template slot="item" slot-scope="data">
                          {{ data.item | pendency }}
                        </template>
                        <template slot="selection" slot-scope="item">
                          <span class="primary--text">{{ item.item | pendency }}</span>
                        </template></v-combobox
                      >
                    </v-list-item>

                    <v-list-item class="pa-4">
                      <v-btn
                        v-model="tituloBotaoFiltro"
                        :disabled="habilitarBotaoFiltro"
                        :style="
                          habilitarBotaoFiltro
                            ? 'width: 352px; max-height: 48px !important; min-height: 48px !important; background-color: #e7e7fa !important;color:#8686A2!important'
                            : 'width: 352px; max-height: 48px !important; min-height: 48px !important; background-color: var(--v-primary-base) !important;color:white!important'
                        "
                        @click="callFilter"
                      >
                        {{ tituloBotaoFiltro }}
                      </v-btn>
                    </v-list-item>
                    <v-list-item class="pb-4">
                      <v-btn :disabled="habilitarBotaoLimparFiltro" class="clear-filter-enabled" @click="limparFiltro">
                        <span
                          class="material-symbols-outlined"
                          style="max-width: 1px; position: fixed; margin-left: -150px"
                        >
                          delete
                        </span>
                        Limpar filtro</v-btn
                      >
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </span>
            <template>
              <v-btn
                id="exportBtn"
                class="btn-exportar-enabled ml-2 border-0"
                style="width: 127px"
                icon
                @click="exportar()"
              >
                <v-icon>mdi-download-outline</v-icon> Exportar
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </v-card-title>

      <v-row>
        <v-col cols="12" sm="12">
          <template>
            <div class="text-right">
              <v-chip v-if="showChipTodasEmpresas && !openMenu" class="ma-1" close @click:close="removeTodasEmpresas()">
                Todas as empresas ({{ allEmpresas }})
              </v-chip>

              <div v-if="showChipEmpresa && !openMenu">
                <v-chip
                  v-for="company in filter.cnpjs"
                  :key="company.cnpj"
                  v-model="company.isOpen"
                  class="ma-1"
                  close
                  @input="onClose(company)"
                  @click:close="removeTodasEmpresas()"
                >
                  {{ company.socialName }}
                </v-chip>
              </div>

              <v-chip
                v-if="showChipePeriodoEmissao && !openMenu"
                class="ma-1"
                close
                @click:close="removeChipPeriodoEmissao()"
              >
                {{ concatDate(filter.emissao, 'Emissão: ') }}
              </v-chip>

              <v-chip
                v-if="showChipPeriodoValidacao && !openMenu"
                class="ma-1"
                close
                @click:close="removeChipPeriodoValidade()"
              >
                {{ concatDate(filter.validade, 'Validade: ') }}
              </v-chip>

              <v-chip v-if="showChipSituacao && !openMenu" class="ma-1" close @click:close="removeSituacao()">
                {{ capitalize(filter.situacao) }}
              </v-chip>

              <v-chip v-if="showChipInclusao && !openMenu" class="ma-1" close @click:close="removeInclusao()">
                {{ capitalize(filter.inclusao) }}
              </v-chip>
            </div>
          </template>
        </v-col>
      </v-row>
      <v-data-table
        class="pb-5"
        :headers="headers"
        :items="certificates"
        :server-items-length="totalCertificates"
        :items-per-page="10"
        :items-per-page-options="pagination.page"
        :page="pagination.page"
        :options.sync="pagination"
        :footer-props="{
          'items-per-page-options': [10, 50, 100],
        }"
      >
        <template v-slot:item.empresa="{ item }">
          {{ item.empresa }}
        </template>

        <template v-slot:item.cnpj="{ item }">
          {{ item.cnpj | cnpj }}
        </template>

        <template v-slot:item.emissao="{ item }">
          {{ item.emissao | date }}
        </template>

        <template v-slot:item.validade="{ item }">
          {{ item.validade | date }}
        </template>

        <template v-slot:item.codigo="{ item }">
          {{ item.codigo }}
        </template>

        <template v-slot:item.inclusao="{ item }">
          {{ item.inclusao }}
        </template>

        <template v-slot:item.situacao="{ item }">
          <span v-if="item.situacao === 'Regular'" style="color: #288569">{{ item.situacao }}</span>
          <span v-else style="color: #962d33">{{ item.situacao }}</span>
        </template>

        <template v-slot:item.actions="{ item }">
          <div class="button-container">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="crf-table-actions">
                  <v-btn icon small class="button-size" v-bind="attrs" v-on="on">
                    <router-link
                      id="btnHistory"
                      style="text-decoration: none"
                      class="text-button"
                      :to="`/crf/historico/${item.id}`"
                      ><v-icon small> mdi-history</v-icon>
                    </router-link>
                  </v-btn>
                </div>
              </template>
              <span>Histórico</span>
            </v-tooltip>
            <!-- <v-btn icon v-if="isAdmin" class="mr-2 button-size" @click="editModal(item)">
              <v-icon> mdi-pencil </v-icon> 
            -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="crf-table-actions">
                  <v-btn id="btnDownload" v-bind="attrs" icon class="button-size" @click="download(item)" v-on="on">
                    <v-icon small> mdi-download </v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Download</span>,
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="crf-table-actions">
                  <v-btn
                    id="btnEdit"
                    v-bind="attrs"
                    :disabled="!isAdmin"
                    icon
                    class="button-size"
                    @click="editModal(item)"
                    v-on="on"
                  >
                    <v-icon small color="success"> mdi-pencil </v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="crf-table-actions">
                  <v-btn
                    id="btnDelete"
                    v-bind="attrs"
                    icon
                    :disabled="!isAdmin"
                    class="button-size"
                    v-on="on"
                    @click="confirmarExlusaoCertidao(item)"
                  >
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <AGDialog
      v-model="dialogExluir"
      title="Excluir certidão"
      primary-button-text="Sim, desejo excluir"
      persistent
      @tertiaryButtonClick="cancelaExclusaoCertidao()"
      @primaryButtonClick="excluirCertidao()"
    >
      Deseja realmente excluir a certidão?
    </AGDialog>

    <modal-crf-nova ref="modalCrfNova" :crf="crfEdit" @refresh="refresh" />
    <ModalHistoryCRF ref="modalHistoryMessage" :history="arrHistoric" />
  </v-main>
</template>

<script>
import AdminServices from '@/services/adminServices.js';
import crfService from '@/services/crfService.js';
import eSocialService from '@/services/eSocialService.js';
import gedService from '@/services/gedService.js';
import UserService from '@/services/userService.js';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { mapGetters } from 'vuex';

import { eventBus } from '../../../main.js';

export default {
  name: 'Crf',
  components: {
    ModalCrfNova: () => import('./components/ModalCrfNova.vue'),
    ModalHistoryCRF: () => import('./components/ModalHistoryCRF.vue'),
    TableSearch: () => import('../commons/TableSearch.vue'),
    AGDialog: () => import('../commons/AGDialog.vue'),
  },

  data() {
    return {
      enableCounter: false,
      closeOnClick: false,
      totalCertificates: 0,
      localPagination: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
      },

      allEmpresas: 0,
      showChipTodasEmpresas: false,
      showChipEmpresa: false,
      showChipePeriodoEmissao: false,
      showChipPeriodoValidacao: false,
      showChipSituacao: false,
      showChipInclusao: false,
      historicUpdate: {
        nome: '',
        email: '',
        cnpj: '',
      },

      ultimaDataAtualizacao: null,
      currentUser: null,
      arrHistoric: null,
      isActive: false,
      totalSelectedFilters: 0,
      closeFilterAutomatically: false,
      dateMessageError: '',
      dateMessageValidadeError: '',
      activeClass: 'active',
      idCertidaoExcluir: null,
      dialogExluir: false,
      filterDateColor: 'primary',
      tituloBotaoFiltro: 'Aplicar filtro',
      modalPickerEmissao: false,
      modalPickerValidade: false,
      loading: false,
      loadingSave: false,
      codigo: '',
      companies: [],
      filter: {
        cnpjs: [],
        emissao: [],
        validade: [],
        situacao: '',
        inclusao: '',
        codigo: '',
      },

      partialFilter: {},
      params: {
        cnpjs: [],
        emissao: [],
        validade: [],
        situacao: '',
        inclusao: '',
        codigo: '',
      },

      options: {},
      myloadingvariable: false,
      certificates: [],
      crfEdit: {},
      isAdmin: false,

      totalItems: 0,
      userEstablishment: [],
      situacoes: ['Regular', 'Irregular'],
      inclusoes: ['Manual', 'Automático'],
      headers: [
        { text: 'Empresa', align: 'start', value: 'empresa', width: '237px' },
        { text: 'CNPJ', align: 'start', value: 'cnpj', width: '160px' },
        { text: 'Emissão', value: 'emissao' },
        { text: 'Validade', value: 'validade' },
        { text: 'Código', value: 'codigo' },
        { text: 'Situação', value: 'situacao', width: '107px' },
        { text: 'Inclusão', value: 'inclusao', align: 'start', width: '104px' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],

      openMenu: false,
      applyClicked: false,
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
    };
  },

  computed: {
    ...mapGetters({
      usuario: 'user/getUserDetails',
      perfis: 'user/userAuthorities',
      userInfo: 'user/userInfo',
    }),

    pagination: {
      get: function () {
        return this.localPagination;
      },

      set: function (value) {
        this.setPagination(value);
      },
    },

    habilitarBotaoLimparFiltro: function () {
      if (this.filter.cnpjs.length > 0) {
        return false;
      } else if (this.filter.situacao) {
        return false;
      } else if (this.filter.inclusao) {
        return false;
      } else if (this.filter.emissao.length == 1 || this.filter.emissao.length == 2) {
        return false;
      } else if (this.filter.validade.length == 1 || this.filter.validade.length == 2) {
        return false;
      }

      return true;
    },

    habilitarBotaoFiltro: function () {
      if (this.dateMessageError || this.dateMessageValidadeError) {
        this.tituloBotaoFiltro = 'Aplicar filtro';
        return true;
      }
      if (this.filter.cnpjs.length > 0) {
        this.tituloBotaoFiltro = 'Aplicar';
        return false;
      }
      if (this.filter.situacao) {
        this.tituloBotaoFiltro = 'Aplicar';
        return false;
      }
      if (this.filter.inclusao) {
        this.tituloBotaoFiltro = 'Aplicar';
        return false;
      }
      if (this.filter.emissao.length == 2) {
        this.tituloBotaoFiltro = 'Aplicar';
        return false;
      }
      if (this.filter.validade.length == 2) {
        this.tituloBotaoFiltro = 'Aplicar';
        return false;
      }

      this.tituloBotaoFiltro = 'Aplicar filtro';
      return true;
    },

    computedDateFormatted() {
      return this.formatDate(this.filter.time_issuance);
    },

    qtdFilter() {
      let qtd = 0;

      Object.entries(this.filter).forEach(([, value]) => {
        if (value) qtd++;
      });

      if (this.filter.emissao.length == 0) {
        qtd--;
      }

      if (this.filter.validade.length == 0) {
        qtd--;
      }

      if (this.filter.cnpjs.length == 0) {
        qtd--;
      }

      return qtd;
    },

    dateEmissaoFormated: {
      get() {
        if (this.filter.emissao.length === 2) {
          let dataFormatada = this.formatDate(this.filter.emissao);
          this.dateMessageError = '';
          return dataFormatada;
        } else if (this.filter.emissao.length === 1) {
          let dataFormatada = this.formatDate(this.filter.emissao);
          this.dateMessageError = 'Preencha a data final';
          return dataFormatada;
        }

        return null;
      },

      set(data) {
        return data;
      },
    },

    dateValidadeFormated: {
      get() {
        if (this.filter.validade.length === 2) {
          let dataFormatada = this.formatDate(this.filter.validade);
          this.dateMessageValidadeError = '';
          return dataFormatada;
        } else if (this.filter.validade.length === 1) {
          let dataFormatada = this.formatDate(this.filter.validade);
          this.dateMessageValidadeError = 'Preencha a data final';
          return dataFormatada;
        }
        return null;
      },

      set(data) {
        return data;
      },
    },
  },

  watch: {
    options: {
      handler() {
        this.findCertificates();
      },
    },
  },

  created() {
    let adminOrNot = this.perfis.filter((n) => n === 'ROLE_ADMIN');
    if (adminOrNot.length > 0) {
      this.isAdmin = true;
    }

    this.$on('update', this.update);

    this.findCertificates();

    this.getCompaniesFiltro();
    this.getEstablishments();
    this.findHistoricAtualicaoData();
  },

  methods: {
    selectAllCompanies(selectedCompanies) {
      const todasEmpresasOption = this.companies.find(
        (company) => typeof company === 'string' && company.includes('Todas'),
      );
      const todasEmpresasIndex = selectedCompanies.indexOf(todasEmpresasOption);

      if (todasEmpresasIndex !== -1) {
        this.filter.cnpjs = this.companies
          .filter((company) => typeof company !== 'string')
          .map((company) => company.details);
        this.filter.cnpjs.push(todasEmpresasOption);
      } else {
        this.filter.cnpjs = selectedCompanies
          .filter((company) => typeof company !== 'string')
          .map((company) => company);
      }
    },

    removeChipPeriodoValidade() {
      this.showChipPeriodoValidacao = false;
      this.filter.validade = [];
      this.params.validade = [];
      this.totalSelectedFilters--;
      this.findCertificates();
    },

    removeChipPeriodoEmissao() {
      this.showChipePeriodoEmissao = false;
      this.filter.emissao = [];
      this.params.emissao = [];
      this.totalSelectedFilters--;
      this.findCertificates();
    },

    removeTodasEmpresas() {
      this.showChipTodasEmpresas = false;
      this.filter.cnpjs = [];
      this.params.cnpjs = [];
      this.showChipEmpresa = false;

      this.totalSelectedFilters--;
      this.findCertificates();
    },

    removeSituacao() {
      this.showChipSituacao = false;
      this.filter.situacao = null;
      this.params.situacao = null;

      this.totalSelectedFilters--;
      this.findCertificates();
    },

    removeInclusao() {
      this.showChipInclusao = false;
      this.filter.inclusao = null;
      this.params.inclusao = null;
      this.totalSelectedFilters--;
      this.findCertificates();
    },

    habilitarChip() {
      this.showChipTodasEmpresas = false;
      this.showChipEmpresa = false;
    },

    update() {
      this.findCertificates();
    },

    setPagination(value) {
      this.localPagination = value;

      this.findCertificates();
    },

    clear() {
      this.showChipEmpresa = false;
      this.showChipePeriodoEmissao = false;
      this.showChipPeriodoValidacao = false;
      this.showChipSituacao = false;
      this.showChipInclusao = false;
      this.showChipTodasEmpresas = false;
    },

    showChips() {
      if (this.companies.length > 0) {
        let todas = this.companies[0];
        if (todas.includes('Todas')) {
          this.allEmpresas = this.companies.length - 1;
        } else {
          this.allEmpresas = 0;
        }
      }
      if (this.filter.emissao.length == 2) {
        this.showChipePeriodoEmissao = true;
      } else {
        this.showChipePeriodoEmissao = false;
      }
      if (this.filter.validade.length == 2) {
        this.showChipPeriodoValidacao = true;
      } else {
        this.showChipPeriodoValidacao = false;
      }
      if (this.filter.inclusao) {
        this.showChipInclusao = true;
      } else {
        this.showChipInclusao = false;
      }
      if (this.filter.situacao) {
        this.showChipSituacao = true;
      } else {
        this.showChipSituacao = false;
      }

      if (typeof this.filter.cnpjs[0] === 'string') {
        this.showChipTodasEmpresas = true;
        this.showChipEmpresa = false;
      } else {
        this.showChipEmpresa = true;
        this.showChipTodasEmpresas = false;
      }
    },

    async findHistoricAtualicaoData() {
      this.myloadingvariable = true;
      let response = await crfService.historic.search();
      this.historics = response.data;
      this.myloadingvariable = false;

      if (this.historics.length > 0) {
        let response = await crfService.historic.getDataAtualizada();
        this.ultimaDataAtualizacao = response.data.dataAtualizada;
      }
    },

    async historic() {
      try {
        this.$refs.modalHistoryMessage.$emit('open');
      } catch (error) {
        console.log('Erro ao buscar histórico da mensagem: ', error);
      }
    },

    clickButtonFilter() {
      this.partialFilter = cloneDeep(this.filter);
      this.openMenu = true;
      this.isActive = true;
    },

    closeFromX() {
      this.isActive = !this.isActive;
      this.filter = this.partialFilter;
      this.openMenu = false;
      if (this.applyClicked === false) {
        this.filter = {
          cnpjs: [],
          emissao: [],
          validade: [],
          situacao: '',
          inclusao: '',
          codigo: '',
        };
        this.params = {
          cnpjs: [],
          emissao: [],
          validade: [],
          situacao: '',
          inclusao: '',
          codigo: '',
        };
        this.checkSelectedFilters();
      }

      this.checkSelectedFilters();
    },

    clickChipCompany(value, paramArr) {
      if (this.totalSelectedFilters > 1) {
        this.enableCounter = false;
      }
      paramArr.splice(value, 1);
      this.findCertificates();
    },

    concatDate(arrayDate) {
      let dataInicioFim = '';
      let inicio = arrayDate[1].split('-');
      let fim = arrayDate[0].split('-');

      const dataInicial = new Date(inicio[2], inicio[1], inicio[0]);
      const dataFinal = new Date(fim[2], fim[1], fim[0]);

      if (dataInicial.getTime() < dataFinal.getTime()) {
        dataInicioFim =
          `${inicio[2].substring(0, 2)}/${inicio[1]}/${inicio[0]}` +
          ' a ' +
          `${fim[2].substring(0, 2)}/${fim[1]}/${fim[0]}`;
      } else {
        dataInicioFim =
          `${fim[2].substring(0, 2)}/${fim[1]}/${fim[0]}` +
          ' a ' +
          `${inicio[2].substring(0, 2)}/${inicio[1]}/${inicio[0]}`;
      }
      return dataInicioFim;
    },

    capitalize(str) {
      if (str) {
        let withoutUnderline = str.split('_').join(' ').toLowerCase();
        return withoutUnderline[0].toUpperCase() + withoutUnderline.slice(1);
      }
    },

    clickChip(chipName) {
      switch (chipName) {
        case 'situacao':
          this.filter.situacao = '';
          break;
        case 'inclusao':
          this.filter.inclusao = '';
          break;

        default:
          break;
      }
      this.findCertificates();
    },

    iniciaFiltro() {
      this.filter = {};
      this.filter.emissao = [];
      this.filter.validade = [];
    },

    async getEstablishments() {
      let { data } = await AdminServices.userEstablishment.get(this.usuario.id);

      let userEstablishments = data.map((item) => {
        return { name: item.establishment.socialName, cnpj: item.establishment.details.cnpj };
      });
      userEstablishments = sortBy(userEstablishments, ['name']);
      let establishments = userEstablishments.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);
      this.userEstablishment = establishments;
    },

    callFilter() {
      this.applyClicked = true;
      this.findCertificates();
      this.openMenu = false;
    },

    async findCertificates() {
      this.params = this.pegarParamsParaRequest();

      let pagination;

      if (this.localPagination.sortBy && this.localPagination.sortBy.length > 0) {
        let desc = this.localPagination.sortDesc && this.localPagination.sortDesc[0];
        let sort = this.localPagination.sortBy[0] + (desc ? ',desc' : ',asc');
        pagination = { size: this.localPagination.itemsPerPage, page: this.localPagination.page - 1, sort: sort };
      } else {
        pagination = { size: this.localPagination.itemsPerPage, page: this.localPagination.page - 1 };
      }

      let response = await crfService.crf.search(pagination, this.params);
      this.certificates = response.data.content;
      if (this.certificates.length > 0) {
        this.myloadingvariable = false;
      }
      this.enableCounter = true;
      this.totalCertificates = response.data.totalElements;
      this.showChips();
      this.saveHistoricUpdate();
    },

    async getCertificates() {
      this.myloadingvariable = true;
      let adminOrNot = this.perfis.filter((n) => n === 'ROLE_ADMIN');
      if (adminOrNot.length > 0) {
        this.isAdmin = true;
      }

      let response = await crfService.crf.getAll();
      if (response) {
        this.myloadingvariable = false;
      }
      this.certificates = response.data;
    },

    async fetch() {
      this.myloadingvariable = true;
      this.findCertificates();
    },

    async getCompanies() {
      let adminOrNot = this.perfis.filter((n) => n === 'ROLE_ADMIN');

      if (adminOrNot.length > 0) {
        this.isAdmin = true;
      }
      let { data } = await eSocialService.establishment.getByUserId();

      let companies = data.filter((v, i, a) => a.findIndex((t) => t.cnpjMatriz === v.cnpjMatriz) === i);
      companies = sortBy(companies, ['socialName', 'stateCode', 'city']);
      let establishments = data.filter((v, i, a) => a.findIndex((t) => t.cnpjFilial === v.cnpjFilial) === i);
      establishments = sortBy(establishments, ['socialName', 'stateCode', 'city']);

      this.userEstablishment = establishments;
    },

    async getCompaniesFiltro() {
      let response = await AdminServices.userEstablishment.get(this.userInfo.id);
      let results = response.data;

      if (results && results.length > 0) {
        this.companies = ['Todas as empresas (' + results.length + ')'];
        let establishments = [];

        results.forEach((item) => {
          establishments.push(item.establishment);
        });

        let groups = groupBy(establishments, 'socialName');

        groups = Object.entries(groups).map(([key, value]) => ({
          company: key,
          establishments: value,
        }));

        groups.forEach((item, index) => {
          if (index > 0) {
            this.companies.push({ divider: true });
          }
          let group = item.company;
          this.companies.push({ header: group });
          item.establishments.forEach((innerItem) => {
            innerItem.group = group;
            this.companies.push(innerItem);
          });
        });
      }
    },

    async limparFiltro() {
      this.enableCounter = false;
      this.openMenu = true;
      this.applyClicked = false;
      this.clear();
      let response = await UserService.getUser();
      this.currentUser = response.data;
      this.isActive = !this.isActive;
      this.tituloBotaoFiltro = 'Aplicar filtro';

      this.dateMessageError = '';
      this.dateMessageValidadeError = '';
      this.filter = {
        cnpjs: [],
        emissao: [],
        validade: [],
        situacao: '',
        inclusao: '',
        codigo: '',
      };
      this.params = {
        cnpjs: [],
        emissao: [],
        validade: [],
        situacao: '',
        inclusao: '',
        codigo: '',
      };
      this.partialFilter = {};
      this.checkSelectedFilters();
      this.fetch();
    },

    formatDateEdicao(item) {
      let data = item.split('-');
      return data[2].substring(0, 2) + '/' + data[1] + '/' + data[0];
    },

    formatDate(item) {
      if (item.length == 2) {
        let dataInicioFim = '';
        let inicio = item[1].split('-');
        let fim = item[0].split('-');

        const dataInicial = new Date(inicio[2], inicio[1], inicio[0]);
        const dataFinal = new Date(fim[2], fim[1], fim[0]);

        if (dataInicial.getTime() < dataFinal.getTime()) {
          dataInicioFim =
            `${inicio[2].substring(0, 2)}/${inicio[1]}/${inicio[0]}` +
            ' a ' +
            `${fim[2].substring(0, 2)}/${fim[1]}/${fim[0]}`;
        } else {
          dataInicioFim =
            `${fim[2].substring(0, 2)}/${fim[1]}/${fim[0]}` +
            ' a ' +
            `${inicio[2].substring(0, 2)}/${inicio[1]}/${inicio[0]}`;
        }

        return dataInicioFim;
      } else if (item.length == 1) {
        let data = item[0].split('-');
        let dataSelecionada = `${data[2].substring(0, 2)}/${data[1]}/${data[0]}` + ' a';
        return dataSelecionada;
      }
      return null;
    },

    editModal(item) {
      let itemNovo = cloneDeep(item);
      if (itemNovo.validade) {
        itemNovo.validade = item.validade.substring(0, 10);
      }

      if (itemNovo.emissao) {
        itemNovo.emissao = item.emissao.substring(0, 10);
      }

      this.crfEdit = itemNovo;
      this.crfEdit.editando = true;

      this.$refs.modalCrfNova.$emit('edit');
    },

    async download(item) {
      this.downloading = true;

      this.downloading = true;

      let response = await gedService.files.get(item.idArquivoGED);

      let fileName = `certificado_crf.pdf`;
      this.downloadDocument(response, fileName);

      // const linkSource = 'data:application/pdf;base64,' + item.pdf;
      // const downloadLink = document.createElement('a');
      // downloadLink.href = linkSource;
      // downloadLink.download = 'certificado_crf.pdf';
      // downloadLink.click();
      // this.showToast('Download iniciado. Verifique o arquivo em seu computador.', 'success', 78);
    },

    downloadDocument(responseGed, fileName) {
      const linkSource = 'data:application/octet-stream;base64,' + responseGed.data.base64;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      this.showToast('Download iniciado. Verifique o arquivo em seu computador.', 'success', 78);
    },

    confirmarExlusaoCertidao(certidao) {
      this.idCertidaoExcluir = certidao.id;
      this.dialogExluir = true;
    },

    cancelaExclusaoCertidao() {
      this.idCertidaoExcluir = null;
      this.dialogExluir = false;
    },

    close() {
      this.$emit('refresh');
      this.dialogExluir = false;
    },

    async excluirCertidao() {
      await crfService.crf.delete(this.idCertidaoExcluir);
      this.dialogExluir = false;
      this.idCertidaoExcluir = null;
      this.certidaoExcluidaSucess = true;
      this.showToast('Certidão de Regularidade do FGTS excluída com sucesso!', 'success', 78);
      this.fetch();
      this.saveHistoricUpdate();
    },

    openModal() {
      this.$refs.modalCrfNova.$emit('open');
    },

    pegarParamsParaRequest() {
      this.filter.cnpjs.forEach((item) => {
        if (typeof item === 'string') {
          if (!item.includes('Todas')) {
            this.params.cnpjs.push(item);
          }
        } else {
          this.params.cnpjs.push(item.details.cnpj);
        }
      });

      if (this.filter.codigo) {
        this.params.codigo = this.filter.codigo;
      } else {
        this.params.codigo = '';
      }

      if (this.filter.emissao) {
        this.params.emissao = this.filter.emissao;
      }
      if (this.filter.validade) {
        this.params.validade = this.filter.validade;
      }

      if (this.filter.situacao) {
        if (this.filter.situacao === 'Regular') {
          this.params.situacao = 'REGULAR';
        } else {
          this.params.situacao = 'IRREGULAR';
        }
      }

      if (this.filter.inclusao) {
        if (this.filter.inclusao === 'Automático') {
          this.params.inclusao = 'AUTOMATICO';
        } else {
          this.params.inclusao = 'MANUAL';
        }
      }
      this.checkSelectedFilters();
      return this.params;
    },

    refresh() {
      this.crfEdit = {};
      this.fetch();
    },

    async exportar() {
      let params = { cnpjs: [], emissao: [], validade: [], situacao: '', inclusao: '', codigo: '' };
      let response = await crfService.crf.exportar(params);
      let blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      let date = new Date();

      this.showToast('Download iniciado. Verifique o arquivo em seu computador.', 'success', 78);
      link.download = `CRF - Extraído em ${date.toLocaleDateString('pt-br')}.xlsx`;

      link.click();
      this.saveHistoricUpdate();
    },

    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },

    async fillFilters() {
      try {
        this.filter.emissao[0] = this.getMillisecondsDate(`${this.filter.emissao[0]} 00:00`);
        this.filter.emissao[1] = this.getMillisecondsDate(`${this.filter.emissao[1]} 23:59`);
      } catch (e) {
        console.error('Erro ao carregar os parametros do filtro', e);
      }
    },

    getMillisecondsDate(dateString) {
      var date = new Date(dateString);
      return date.getTime();
    },

    checkSelectedFilters() {
      this.totalSelectedFilters = 0;

      if (this.filter.cnpjs.length > 0) {
        this.totalSelectedFilters++;
      }
      if (this.filter.emissao.length > 0) {
        this.totalSelectedFilters++;
      }
      if (this.filter.validade.length > 0) {
        this.totalSelectedFilters++;
      }
      if (this.filter.situacao) {
        this.totalSelectedFilters++;
      }
      if (this.filter.inclusao) {
        this.totalSelectedFilters++;
      }
      if (this.filter.cnpjs && this.filter.cnpjs.length > 0) {
        this.filter.cnpjs.every((selectedCnpj) => {
          if (typeof selectedCnpj === 'string') {
            if (selectedCnpj.includes('Todas')) {
              this.filter.cnpjs = [selectedCnpj];
              this.companies.forEach((company) => {
                if (company.details) {
                  this.filter.cnpjs.push(company.details.cnpj);
                }
              });
              return false;
            }
          }
          return true;
        });
      }
    },

    saveHistoricUpdate() {
      this.historicUpdate.nome = this.userInfo.firstName;
      this.historicUpdate.email = this.userInfo.emailAddress;
      crfService.historic.save(this.historicUpdate);
    },

    onInputCrfTableSearch() {
      this.findCertificates(true);
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
  text-align: start;
  background-color: #e7e7fa;
  cursor: default;
}

::v-deep .v-data-table-header tr th {
  line-height: 18.62px;
  letter-spacing: 1%;
  height: 51px;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 1px;
  cursor: default;
}

::v-deep .v-data-table-header tr th.text-start span {
  color: var(--v-primary-base) !important;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  cursor: default;
}
/* ::v-deep .v-btn.v-btn--active.v-btn--disabled {
  background-color: #e7e7fa !important;

} */
::v-deep .primary--text.v-btn.v-btn--disabled {
  background-color: #e7e7fa !important;
  /* color: grey !important; */
}
::v-deep .v-label {
  color: var(--v-primary-base) !important;
}

.icon-action {
  margin: 0 !important;
  padding: 0 !important;
}

.secundary-default {
  height: 48px !important;
  width: 130px !important;
  top: 0px;
  border-radius: 4px !important;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid var(--v-primary-base);
  background-color: #fff !important;
  color: var(--v-primary-base) !important;
}
::v-deep .v-data-table tr td.text-start {
  cursor: default !important;
}
.secundary-default-active {
  height: 48px !important;
  width: 130px !important;
  top: 0px;
  border-radius: 4px !important;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  border: 1px solid var(--v-primary-base);
  color: #fff !important;
  background-color: var(--v-primary-base) !important;
}

::v-deep #textPicker:disabled {
  color: var(--v-primary-base) !important;
  background-color: #e7e7fa !important;
}
::v-deep #textPicker {
  color: var(--v-primary-base) !important;
}
.btn-exportar-enabled {
  height: 48px !important;
  top: 0px;
  padding: 12px 16px 12px 16px !important;

  /* Typography  */
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid #fff;
  background-color: #fff !important;
  color: var(--v-primary-base);
}

.btn-exportar-disabled {
  height: 48px !important;
  top: 0px;
  padding: 12px 16px 12px 16px !important;

  /* Typography  */
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  background-color: #e7e7fa !important;
  color: #8d8d99 !important;
}
#date-hint-div {
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: -7%;
}

#date-hint-div p {
  bottom: 3px;
}
::v-deep .material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24 !important;
}
#date-hint-div.v-messages__message {
  color: #962d33;
}

.title {
  height: 43px;
  width: 791px;
  left: 0px;
  top: 0px;
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: 0.01em;
  text-align: left;
  color: var(--v-primary-base);
}
.subtitle {
  height: 24px;
  width: 921px;
  left: 0px;
  top: 51px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #575767;
}
.subtitle-link {
  font-size: 12px !important;
  float: right;
  color: #575767;
  letter-spacing: 0px !important;
}
.subtitle-link a {
  text-decoration: none;
}

.btn-config {
  height: 48px !important;
  top: 0px;
  border-radius: 4px !important;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid var(--v-primary-base);
  background-color: #fff !important;
  color: var(--v-primary-base);
}
.text-link {
  text-decoration: none;
  color: #8d8d99 !important;
}

.clear-filter-enabled {
  width: 352px;
  max-height: 48px !important;
  min-height: 48px !important;
  font-weight: 900;
  font-size: 15px;
  background-color: #ffffff !important;
}
.clear-filter-enabled :hover {
  width: 352px;
  max-height: 48px !important;
  min-height: 48px !important;
  background: #e3ffee;
}
.secundary-default:hover {
  background-color: #E3FFEE !important;
}

::v-deep .v-icon.mdi-chevron-down {
  color: var(--v-primary-base);
}

::v-deep .v-text-field .material-icons-outlined {
  color: var(--v-primary-base);
  width: 18px !important;
  height: 20px !important;
}

::v-deep .crf-table-actions .v-btn {
    margin-right: -1px;
    width: 10px;
}

.button-container {
  display: flex;
  align-items: center;
  margin-right: 0;
}
</style>
