var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c(
        "v-row",
        [
          _c("v-col", {
            staticStyle: { "margin-top": "32px" },
            attrs: { cols: "6" },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticStyle: { "margin-top": "32px", "padding-bottom": "32px" },
              attrs: { cols: "6" },
            },
            [
              _c(
                "router-link",
                {
                  staticStyle: { "text-decoration": "none" },
                  attrs: { to: "/home" },
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "icon-home",
                      staticStyle: { "margin-right": "16px !important" },
                    },
                    [_vm._v("mdi-home-outline")]
                  ),
                ],
                1
              ),
              _c(
                "v-icon",
                { staticClass: "mr-2 text-link", attrs: { small: "" } },
                [_vm._v("mdi-chevron-right")]
              ),
              _c(
                "b",
                {
                  staticClass: "text-link",
                  staticStyle: {
                    color: "#8d8d99",
                    "font-size": "14px",
                    "font-weight": "400",
                    "letter-spacing": "0.01em",
                    "margin-right": "16px !important",
                  },
                },
                [_vm._v(" Certidões")]
              ),
              _c(
                "v-icon",
                { staticClass: "mr-2 text-link", attrs: { small: "" } },
                [_vm._v("mdi-chevron-right")]
              ),
              _c("strong", { staticClass: "text-link" }, [
                _vm._v("Certidões de Regularidade do FGTS"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "6" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  width: "530px",
                  height: "50px",
                  display: "inline-block",
                },
              },
              [
                _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
                  _vm._v("Certidões de Regularidade do FGTS (CRF)"),
                ]),
              ]
            ),
          ]),
          _c("v-col", { attrs: { cols: "2" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  width: "530px",
                  height: "50px",
                  display: "inline-block",
                  "padding-left": "495px",
                },
              },
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        _vm.isAdmin
                          ? {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            color: "success",
                                            disabled: _vm.certificates == [],
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openModal()
                                            },
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "14px !important",
                                          },
                                        },
                                        [_vm._v("+")]
                                      ),
                                      _vm._v(" Adicionar "),
                                    ]
                                  ),
                                ]
                              },
                            }
                          : null,
                      ],
                      null,
                      true
                    ),
                  },
                  [_c("span", [_vm._v("Adicionar uma nova certidão")])]
                ),
              ],
              1
            ),
          ]),
          _c("v-col", { staticClass: "h6", attrs: { cols: "6" } }, [
            _c(
              "p",
              { staticClass: "subtitle", staticStyle: { color: "#575767" } },
              [
                _vm._v(
                  " Gerenciamento de certidões, com inclusão manual e automática, visualização de histórico e download "
                ),
              ]
            ),
          ]),
          _c("v-col", { attrs: { cols: "6" } }, [
            _c(
              "p",
              { staticClass: "subtitle-link" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "pa-4",
                    staticStyle: {
                      color: "#2d5796",
                      "background-color": "transparent",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.historic()
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "material-icons-outlined",
                        staticStyle: { "font-size": "12px !important" },
                      },
                      [_vm._v("restart_alt")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "mx-1",
                        staticStyle: {
                          color: "#2d5796",
                          "font-size": "12px !important",
                        },
                      },
                      [_vm._v(" Atualização realizada ")]
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "#52526b",
                          "font-size": "12px !important",
                        },
                      },
                      [_vm._v(" em " + _vm._s(_vm.ultimaDataAtualizacao) + " ")]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "pl-0 pr-0" },
            [
              _c("TableSearch", {
                attrs: { id: "idSearchBar", label: "Pesquisar por código..." },
                on: { input: _vm.onInputCrfTableSearch },
                model: {
                  value: _vm.filter.codigo,
                  callback: function ($$v) {
                    _vm.$set(_vm.filter, "codigo", $$v)
                  },
                  expression: "filter.codigo",
                },
              }),
              _c("v-spacer"),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-right",
                      attrs: { cols: "12", sm: "12" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "max-width": "384px !important",
                            "min-width": "384px !important",
                            "max-height": "544px !important",
                            "min-height": "544px !important",
                          },
                          attrs: { id: "attachId" },
                        },
                        [
                          _c(
                            "v-menu",
                            {
                              staticStyle: {
                                "max-width": "384px !important",
                                "min-width": "384px !important",
                                "max-height": "544px !important",
                                "min-height": "544px !important",
                              },
                              attrs: {
                                "offset-y": "",
                                left: "",
                                "close-on-content-click": false,
                                "close-on-click": _vm.closeOnClick,
                                attach: "#attachId",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              class: _vm.isActive
                                                ? "secundary-default-active"
                                                : "secundary-default",
                                              staticStyle: { width: "103px" },
                                              attrs: { icon: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.clickButtonFilter()
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-filter-outline"),
                                          ]),
                                          _vm._v(
                                            " Filtro " +
                                              _vm._s(
                                                _vm.totalSelectedFilters > 0 &&
                                                  _vm.enableCounter &&
                                                  !_vm.openMenu
                                                  ? `(${_vm.totalSelectedFilters})`
                                                  : ""
                                              ) +
                                              " "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.openMenu,
                                callback: function ($$v) {
                                  _vm.openMenu = $$v
                                },
                                expression: "openMenu",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "mb-2 pa-0",
                                  staticStyle: {
                                    "max-width": "384px !important",
                                    "min-width": "384px !important",
                                    "max-height": "544px !important",
                                    "min-height": "544px !important",
                                  },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass: "pa-4",
                                      staticStyle: {
                                        "max-height": "64px !important",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "text-h5",
                                          style:
                                            "color: #182026;font-weight:700;line-height:30px!important;font-size:20px!important;height:30px!important",
                                        },
                                        [_vm._v("Filtro")]
                                      ),
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "pa-0 ma-0",
                                          attrs: { icon: "", dark: "" },
                                          on: { click: _vm.closeFromX },
                                        },
                                        [_c("v-icon", [_vm._v("mdi-close")])],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list",
                                    {
                                      staticClass: "ma-0 pa-0",
                                      staticStyle: {
                                        "max-height": "480px !important",
                                        "min-height": "480px !important",
                                        "border-top":
                                          "1px solid var(--v-secondary-lighten5) !important",
                                      },
                                      attrs: { width: "384" },
                                    },
                                    [
                                      _c(
                                        "v-list-item",
                                        { staticClass: "py-4" },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.companies,
                                              "item-text": "details.socialName",
                                              "item-value": (item) =>
                                                item.details,
                                              "return-object": "",
                                              multiple: "",
                                              "append-icon": "mdi-chevron-down",
                                              dense: "",
                                              outlined: "",
                                              label: "Empresas",
                                              clearable: "",
                                              "hide-details": "",
                                            },
                                            on: {
                                              click: _vm.habilitarChip,
                                              change: _vm.selectAllCompanies,
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "selection",
                                                fn: function ({ index }) {
                                                  return [
                                                    index === 0
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "primary--text",
                                                            staticStyle: {
                                                              "max-height":
                                                                "48px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  `${
                                                                    _vm.filter
                                                                      .cnpjs
                                                                      .length -
                                                                      1 ==
                                                                    _vm
                                                                      .companies
                                                                      .length -
                                                                      1
                                                                      ? _vm
                                                                          .companies
                                                                          .length -
                                                                        2
                                                                      : _vm
                                                                          .filter
                                                                          .cnpjs
                                                                          .length -
                                                                        1
                                                                  } selecionado${
                                                                    _vm.filter
                                                                      .cnpjs
                                                                      .length >
                                                                    1
                                                                      ? "s"
                                                                      : ""
                                                                  }`
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "item",
                                                fn: function ({
                                                  item,
                                                  attrs,
                                                  on,
                                                }) {
                                                  return [
                                                    _c(
                                                      "v-list-item",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            class: {
                                                              "pl-10":
                                                                item.group,
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    active,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-list-item-action",
                                                                        [
                                                                          _c(
                                                                            "v-checkbox",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  "input-value":
                                                                                    active,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-content",
                                                                        [
                                                                          item.socialName
                                                                            ? _c(
                                                                                "v-list-item-title",
                                                                                {
                                                                                  domProps:
                                                                                    {
                                                                                      innerHTML:
                                                                                        _vm._s(
                                                                                          item.socialName
                                                                                        ),
                                                                                    },
                                                                                }
                                                                              )
                                                                            : _c(
                                                                                "v-list-item-title",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Todas as empresas (" +
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .filter
                                                                                          .cnpjs
                                                                                          .length ==
                                                                                          0
                                                                                          ? _vm
                                                                                              .filter
                                                                                              .cnpjs
                                                                                              .length
                                                                                          : _vm
                                                                                              .filter
                                                                                              .cnpjs
                                                                                              .length -
                                                                                              1 ==
                                                                                            _vm
                                                                                              .companies
                                                                                              .length -
                                                                                              1
                                                                                          ? _vm
                                                                                              .companies
                                                                                              .length -
                                                                                            2
                                                                                          : _vm
                                                                                              .filter
                                                                                              .cnpjs
                                                                                              .length -
                                                                                            1
                                                                                      ) +
                                                                                      ")"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                          item.details &&
                                                                          item
                                                                            .details
                                                                            .cnpj
                                                                            ? _c(
                                                                                "v-list-item-subtitle",
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "cnpj"
                                                                                        )(
                                                                                          item
                                                                                            .details
                                                                                            .cnpj
                                                                                        )
                                                                                      )
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          "v-list-item",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                            model: {
                                              value: _vm.filter.cnpjs,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.filter,
                                                  "cnpjs",
                                                  $$v
                                                )
                                              },
                                              expression: "filter.cnpjs",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        { staticClass: "pb-4" },
                                        [
                                          _c(
                                            "v-dialog",
                                            {
                                              attrs: {
                                                model: "dialog",
                                                width: "369px",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "v-text-field",
                                                        _vm._g(
                                                          {
                                                            staticStyle: {
                                                              "max-height":
                                                                "48px !important",
                                                              "min-height":
                                                                "48px !important",
                                                            },
                                                            attrs: {
                                                              id: "textPicker",
                                                              outlined: "",
                                                              label:
                                                                "Período de emissão",
                                                              readonly: "",
                                                              dense: "",
                                                              "error-messages":
                                                                _vm.dateMessageError,
                                                              color: "primary",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.dateEmissaoFormated,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.dateEmissaoFormated =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "dateEmissaoFormated",
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "append" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "material-icons-outlined mr-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "event"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            },
                                            [
                                              _c("v-date-picker", {
                                                attrs: {
                                                  "no-title": "",
                                                  range: "",
                                                },
                                                on: {
                                                  change:
                                                    _vm.checkSelectedFilters,
                                                },
                                                model: {
                                                  value: _vm.filter.emissao,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.filter,
                                                      "emissao",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "filter.emissao",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass: "pb-4",
                                          staticStyle: {
                                            color: "var(--v-primary-base)",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-dialog",
                                            {
                                              attrs: {
                                                model: "dialog",
                                                width: "369px",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "v-text-field",
                                                        _vm._g(
                                                          {
                                                            staticStyle: {
                                                              "max-height":
                                                                "48px !important",
                                                              "min-height":
                                                                "48px !important",
                                                            },
                                                            attrs: {
                                                              id: "textPicker",
                                                              color: "primary",
                                                              outlined: "",
                                                              label:
                                                                "Período de validade",
                                                              readonly: "",
                                                              dense: "",
                                                              "error-messages":
                                                                _vm.dateMessageValidadeError,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.dateValidadeFormated,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.dateValidadeFormated =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "dateValidadeFormated",
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "append" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "material-icons-outlined mr-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "event"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            },
                                            [
                                              _c("v-date-picker", {
                                                attrs: {
                                                  "no-title": "",
                                                  range: "",
                                                },
                                                on: {
                                                  change:
                                                    _vm.checkSelectedFilters,
                                                },
                                                model: {
                                                  value: _vm.filter.validade,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.filter,
                                                      "validade",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "filter.validade",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        { staticClass: "pb-4" },
                                        [
                                          _c("v-combobox", {
                                            staticStyle: {
                                              "max-height": "48px !important",
                                              "min-height": "48px !important",
                                            },
                                            attrs: {
                                              items: _vm.situacoes,
                                              label: "Situação",
                                              "append-icon": "mdi-chevron-down",
                                              outlined: "",
                                              dense: "",
                                            },
                                            on: {
                                              change: _vm.checkSelectedFilters,
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "item",
                                                fn: function (data) {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("pendency")(
                                                            data.item
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "selection",
                                                fn: function (item) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "primary--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "pendency"
                                                              )(item.item)
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                            model: {
                                              value: _vm.filter.situacao,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.filter,
                                                  "situacao",
                                                  $$v
                                                )
                                              },
                                              expression: "filter.situacao",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        { staticClass: "pb-4" },
                                        [
                                          _c("v-combobox", {
                                            class: _vm.activeClass,
                                            staticStyle: {
                                              "max-height": "48px !important",
                                              "min-height": "48px !important",
                                            },
                                            attrs: {
                                              "append-icon": "mdi-chevron-down",
                                              items: _vm.inclusoes,
                                              label: "Inclusão",
                                              outlined: "",
                                              dense: "",
                                            },
                                            on: {
                                              change: _vm.checkSelectedFilters,
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "item",
                                                fn: function (data) {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("pendency")(
                                                            data.item
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "selection",
                                                fn: function (item) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "primary--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("pendency")(
                                                              item.item
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                            model: {
                                              value: _vm.filter.inclusao,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.filter,
                                                  "inclusao",
                                                  $$v
                                                )
                                              },
                                              expression: "filter.inclusao",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        { staticClass: "pa-4" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              style: _vm.habilitarBotaoFiltro
                                                ? "width: 352px; max-height: 48px !important; min-height: 48px !important; background-color: #e7e7fa !important;color:#8686A2!important"
                                                : "width: 352px; max-height: 48px !important; min-height: 48px !important; background-color: var(--v-primary-base) !important;color:white!important",
                                              attrs: {
                                                disabled:
                                                  _vm.habilitarBotaoFiltro,
                                              },
                                              on: { click: _vm.callFilter },
                                              model: {
                                                value: _vm.tituloBotaoFiltro,
                                                callback: function ($$v) {
                                                  _vm.tituloBotaoFiltro = $$v
                                                },
                                                expression: "tituloBotaoFiltro",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.tituloBotaoFiltro
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        { staticClass: "pb-4" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "clear-filter-enabled",
                                              attrs: {
                                                disabled:
                                                  _vm.habilitarBotaoLimparFiltro,
                                              },
                                              on: { click: _vm.limparFiltro },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "material-symbols-outlined",
                                                  staticStyle: {
                                                    "max-width": "1px",
                                                    position: "fixed",
                                                    "margin-left": "-150px",
                                                  },
                                                },
                                                [_vm._v(" delete ")]
                                              ),
                                              _vm._v(" Limpar filtro"),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-exportar-enabled ml-2 border-0",
                            staticStyle: { width: "127px" },
                            attrs: { id: "exportBtn", icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.exportar()
                              },
                            },
                          },
                          [
                            _c("v-icon", [_vm._v("mdi-download-outline")]),
                            _vm._v(" Exportar "),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "12" } },
                [
                  [
                    _c(
                      "div",
                      { staticClass: "text-right" },
                      [
                        _vm.showChipTodasEmpresas && !_vm.openMenu
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "ma-1",
                                attrs: { close: "" },
                                on: {
                                  "click:close": function ($event) {
                                    return _vm.removeTodasEmpresas()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " Todas as empresas (" +
                                    _vm._s(_vm.allEmpresas) +
                                    ") "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.showChipEmpresa && !_vm.openMenu
                          ? _c(
                              "div",
                              _vm._l(_vm.filter.cnpjs, function (company) {
                                return _c(
                                  "v-chip",
                                  {
                                    key: company.cnpj,
                                    staticClass: "ma-1",
                                    attrs: { close: "" },
                                    on: {
                                      input: function ($event) {
                                        return _vm.onClose(company)
                                      },
                                      "click:close": function ($event) {
                                        return _vm.removeTodasEmpresas()
                                      },
                                    },
                                    model: {
                                      value: company.isOpen,
                                      callback: function ($$v) {
                                        _vm.$set(company, "isOpen", $$v)
                                      },
                                      expression: "company.isOpen",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(company.socialName) + " "
                                    ),
                                  ]
                                )
                              }),
                              1
                            )
                          : _vm._e(),
                        _vm.showChipePeriodoEmissao && !_vm.openMenu
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "ma-1",
                                attrs: { close: "" },
                                on: {
                                  "click:close": function ($event) {
                                    return _vm.removeChipPeriodoEmissao()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.concatDate(
                                        _vm.filter.emissao,
                                        "Emissão: "
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.showChipPeriodoValidacao && !_vm.openMenu
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "ma-1",
                                attrs: { close: "" },
                                on: {
                                  "click:close": function ($event) {
                                    return _vm.removeChipPeriodoValidade()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.concatDate(
                                        _vm.filter.validade,
                                        "Validade: "
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.showChipSituacao && !_vm.openMenu
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "ma-1",
                                attrs: { close: "" },
                                on: {
                                  "click:close": function ($event) {
                                    return _vm.removeSituacao()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.capitalize(_vm.filter.situacao)
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.showChipInclusao && !_vm.openMenu
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "ma-1",
                                attrs: { close: "" },
                                on: {
                                  "click:close": function ($event) {
                                    return _vm.removeInclusao()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.capitalize(_vm.filter.inclusao)
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "pb-5",
            attrs: {
              headers: _vm.headers,
              items: _vm.certificates,
              "server-items-length": _vm.totalCertificates,
              "items-per-page": 10,
              "items-per-page-options": _vm.pagination.page,
              page: _vm.pagination.page,
              options: _vm.pagination,
              "footer-props": {
                "items-per-page-options": [10, 50, 100],
              },
            },
            on: {
              "update:options": function ($event) {
                _vm.pagination = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.empresa",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.empresa) + " ")]
                },
              },
              {
                key: "item.cnpj",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(_vm._f("cnpj")(item.cnpj)) + " ")]
                },
              },
              {
                key: "item.emissao",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(_vm._f("date")(item.emissao)) + " "),
                  ]
                },
              },
              {
                key: "item.validade",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(_vm._f("date")(item.validade)) + " "),
                  ]
                },
              },
              {
                key: "item.codigo",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.codigo) + " ")]
                },
              },
              {
                key: "item.inclusao",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.inclusao) + " ")]
                },
              },
              {
                key: "item.situacao",
                fn: function ({ item }) {
                  return [
                    item.situacao === "Regular"
                      ? _c("span", { staticStyle: { color: "#288569" } }, [
                          _vm._v(_vm._s(item.situacao)),
                        ])
                      : _c("span", { staticStyle: { color: "#962d33" } }, [
                          _vm._v(_vm._s(item.situacao)),
                        ]),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "button-container" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "crf-table-actions" },
                                        [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "button-size",
                                                  attrs: {
                                                    icon: "",
                                                    small: "",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass: "text-button",
                                                  staticStyle: {
                                                    "text-decoration": "none",
                                                  },
                                                  attrs: {
                                                    id: "btnHistory",
                                                    to: `/crf/historico/${item.id}`,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v(" mdi-history")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Histórico")])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "crf-table-actions" },
                                        [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "button-size",
                                                  attrs: {
                                                    id: "btnDownload",
                                                    icon: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.download(item)
                                                    },
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v(" mdi-download ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Download")]), _vm._v(", ")]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "crf-table-actions" },
                                        [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "button-size",
                                                  attrs: {
                                                    id: "btnEdit",
                                                    disabled: !_vm.isAdmin,
                                                    icon: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editModal(item)
                                                    },
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "success",
                                                  },
                                                },
                                                [_vm._v(" mdi-pencil ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Editar")])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "crf-table-actions" },
                                        [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "button-size",
                                                  attrs: {
                                                    id: "btnDelete",
                                                    icon: "",
                                                    disabled: !_vm.isAdmin,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.confirmarExlusaoCertidao(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v(" mdi-delete ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Excluir")])]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "AGDialog",
        {
          attrs: {
            title: "Excluir certidão",
            "primary-button-text": "Sim, desejo excluir",
            persistent: "",
          },
          on: {
            tertiaryButtonClick: function ($event) {
              return _vm.cancelaExclusaoCertidao()
            },
            primaryButtonClick: function ($event) {
              return _vm.excluirCertidao()
            },
          },
          model: {
            value: _vm.dialogExluir,
            callback: function ($$v) {
              _vm.dialogExluir = $$v
            },
            expression: "dialogExluir",
          },
        },
        [_vm._v(" Deseja realmente excluir a certidão? ")]
      ),
      _c("modal-crf-nova", {
        ref: "modalCrfNova",
        attrs: { crf: _vm.crfEdit },
        on: { refresh: _vm.refresh },
      }),
      _c("ModalHistoryCRF", {
        ref: "modalHistoryMessage",
        attrs: { history: _vm.arrHistoric },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }